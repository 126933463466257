import { toast } from 'material-react-toastify';
import React, { useCallback, useMemo, useState } from 'react';
import { createImage, getImage } from '../services/bannerbear';
import { BANNER_BEAR_TEMPLATE_ID, REPLICATE_VERSION_ID, BANNER_BEAR_FREE_TEMPLATE_ID, CLOUDINARY_CLOUD_NAME } from '../services/config/config';
import { createDiffussionImage, getDiffussionImage } from '../services/stablediffusion';
import Loader from './layout/Loader';
import { useAuth0 } from "@auth0/auth0-react";
import SignIn from './elements/Signin';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import UploadCloudinary from '../services/cloudinary';
import { Cloudinary, CloudinaryImage, Transformation } from '@cloudinary/url-gen';
import { source } from '@cloudinary/url-gen/actions/overlay';
import { Position } from '@cloudinary/url-gen/qualifiers';
import { image as clImage, text } from '@cloudinary/url-gen/qualifiers/source';
import { TextStyle } from '@cloudinary/url-gen/qualifiers/textStyle';
import { compass } from '@cloudinary/url-gen/qualifiers/gravity';
import { byAngle } from '@cloudinary/url-gen/actions/rotate';
import { scale } from '@cloudinary/url-gen/actions/resize';

const stripePromise = loadStripe('pk_test_zcF2rEStX47vh8YwGVrW8rmc');

export default function RenderForm() {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
    justLoaded: true,
  });
  const [selectedImage, setSelectedImage] = useState('');
  const [upload, setUpload] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [version, setVersion] = useState('free');
  const [image, setImage] = useState('');
  const [diffusionImage, setDiffusionImage] = useState('');
  const [bearsID, setBearsID] = useState('');
  const [diffusionImgURL, setDiffusionImgURL] = useState('');
  const [imageLoading, setImageLoading] = useState(false);
  const [paidImage, setPaidImage] = useState('');
  const [inputs, setInputs] = useState({
    bandName: '',
    venueName: '',
    date: '',
    time: '',
    address: '',
    ticketLink: '',
    urlName: '',
    imageDescription: '',
    price: ''
  });

  const handleOnChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
      event.preventDefault();

      if (elements == null) {
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        console.log('[error]', error);
      }
      else {
        // close the modal on get div id
        document.getElementById('closeModal').click();
        generatePaidVersion();
        console.log('[PaymentMethod]', paymentMethod);
      }
    };


    return (
      <div className='card card-body col-md-12'>
        <form onSubmit={handleSubmit}>
          <p className='text-success' style={{ marginBottom: '20px' }}>“For Demo use 4242 4242 4242 4242 / 04/24 / 242”</p>
          <CardElement />
          <button style={{ marginTop: '20px', }} className='btn btn-md btn-success' type="submit" disabled={!stripe || !elements}>
            Pay
          </button>
          {/* <img width='10%' style={{  backgroundColor: '#fff', float: 'right' }} src={'https://logos-world.net/wp-content/uploads/2021/03/Stripe-Emblem.png'} alt='stripeLogo' /> */}
          {/* <img style={{ marginLeft: '20px', marginTop: '20px' }} src="https://front.dreamstime.com/img/cards-icons-nopaypal.png" alt="" /> */}
        </form>
      </div>
    );
  };


  const fetchBearsImage = useCallback(async (id) => {  // // Step 7 == Fetch created bears poster and return image url 
    const bearsResponse = await getImage(id);
    if (bearsResponse.image_url === null) {
      setTimeout(() => fetchBearsImage(id), 10000);
    }
    setStatus({
      submitting: false,
    });
    setImage(bearsResponse.image_url);
    setTimeout(() => {
      setImageLoading(false);
      toast.success("Image created successfully");
    }, 1000);

  }, []);


  const cld = new Cloudinary({
    cloud: {
      cloudName: CLOUDINARY_CLOUD_NAME,
    },
  });

  const handleFile = async e => {
    setUploadLoading(true);
    const file = e.target.files[0];
    const upload = await UploadCloudinary(file);
    setPaidImage(upload.secure_url);
    const myImage = cld.image(upload.public_id);
    myImage
      .overlay(
        source(
          clImage("poster-bot/Vector.png").transformation(
            new Transformation().rotate(byAngle(12)).resize(scale().width(200))
          )
          // text('Made By Poster Bot', new TextStyle('arial', 30))
          //   .textColor('white')
          //   .backgroundColor('#F4A628')
        ).position(new Position().offsetX(-30).offsetY(30))
      )
      .format('png');
    const imageUrl = myImage.toURL();
    setDiffusionImgURL(imageUrl);
    setSelectedImage(upload.original_filename);
    setUploadLoading(false);
  }

  const fetchImage = useCallback(async (diffusionImage, version) => { // Step 5 == Access Image created on bears

    const bannerTemplateId = version === 'free' ? BANNER_BEAR_FREE_TEMPLATE_ID : BANNER_BEAR_TEMPLATE_ID;
    setImage('');
    const body = {
      "template": bannerTemplateId,
      "modifications": [
        {
          "name": "rectangle_4",
          "color": null
        },
        {
          "name": "bandName",
          "text": inputs.bandName,
          "color": null,
          "background": null
        },
        {
          "name": "venueName",
          "text": inputs.venueName,
          "color": null,
          "background": null
        },
        {
          "name": "dateTime",
          "text": `${inputs.date}  ${inputs.time}`,
          "color": null,
          "background": null
        },
        {
          "name": "address",
          "text": inputs.address,
          "color": null,
          "background": null
        },
        {
          "name": "rectangle_9",
          "color": null
        },
        {
          "name": "generatedImage",
          "image_url": diffusionImage[0]
        },
        {
          "name": "watermark",
          "image_url": "https://ucarecdn.com/7099efcc-b82a-4b19-adcb-a0a688432f9e/Vector2.png"
        },
        {
          "name": "qr_code",
          "image_url": `https://api.qrserver.com/v1/create-qr-code/?data=${inputs.ticketLink}&amp;size=500x500`
        }
      ],
      "webhook_url": null,
      "transparent": false,
      "metadata": null
    }

    const createBearImage = await createImage(body);
    setBearsID(createBearImage.uid)
    setTimeout(() => fetchBearsImage(createBearImage.uid), 5000);// Step 6 == Fetch created bears image 


  }, [fetchBearsImage, inputs.address, inputs.bandName, inputs.date, inputs.ticketLink, inputs.time, inputs.venueName]);


  const fetchDiffusionImage = useCallback(async (url) => { // Step 3 == Access Image

    const diffusionResponse = await getDiffussionImage(url);
    if (diffusionResponse.output === null) {
      setTimeout(() => fetchDiffusionImage(url), 5000);
    }
    setDiffusionImage(diffusionResponse.output);
    if (diffusionResponse.output) {
      setTimeout(() => fetchImage(diffusionResponse.output, 'free'), 5000);  // Step 4 == Create Bears Image from all records provided
    }

  }, [fetchImage]);

  const generateImage = useCallback(async () => {  // Step 1 == Generate Image from form

    if (!inputs.bandName || !inputs.venueName || !inputs.time || !inputs.address || !inputs.ticketLink) {
      toast.error("Please fill all fields");
      return;
    }
    try {
      setStatus({ justLoaded: false });
      setImageLoading(true);
      setStatus({
        submitting: true,
      });
      setInputs({ ...inputs });

      if (!upload) {
        const createDiffusionImage = await createDiffussionImage({
          "version": REPLICATE_VERSION_ID,
          "input": {
            "prompt": inputs.imageDescription,
          }
        })

        setDiffusionImgURL(createDiffusionImage.urls.get);

        setTimeout(() => fetchDiffusionImage(createDiffusionImage.urls.get), 10000); // Step 2 == Get generated image from diffusion
      } else {
        setTimeout(() => fetchImage([diffusionImgURL], 'paid'), 5000);
      }

    } catch (err) {
      toast.error("Error, try again");
      setInputs({ ...inputs });
    }

  }, [diffusionImgURL, fetchDiffusionImage, fetchImage, inputs, upload]);


  const tryAgain = () => {
    setImage('');
    setDiffusionImage('');
    setBearsID('');
    setVersion('free');
    setDiffusionImgURL('');
    setInputs({ ...inputs });
    setStatus({ justLoaded: true });
  };

  const toggleUpload = (e) => {
    setUpload(!upload);
  };

  const chooseFile = useCallback(() => {
    const dropArea = document.querySelector(".drop_box");
    const button = dropArea.querySelector("button");
    const input = dropArea.querySelector("input");
    button.onclick();
    input.click();
  }, [])

  const uploadFile = useMemo(() => {
    return (
      <div className="drop_box">
        <header>
          {selectedImage !== '' ? (
            <>
              <div>{uploadLoading ? (
                <div className="spinner-border spinner-border-sm spinner" style={{ width: '100%', height: '100%', marginTop: '10px', color: '#F4A628' }} role="status">

                </div>
              ) : (
                <div className="alert alert-primary" role="alert">
                  <span>{selectedImage}</span>
                </div>
              )}
              </div>
            </>
          ) : (
            <div>
              {uploadLoading ? (
                <div className="spinner-border spinner-border-sm spinner" style={{ width: '100%', height: '100%', marginTop: '10px', color: '#F4A628' }} role="status">

                </div>
              ) : (
                <span>Drop your file here, or click here to select.</span>
              )}
            </div>
          )}
        </header>
        <p>PNG,JPG</p>
        <input name="file" type="file" onChange={handleFile} hidden accept='png, jpg' id="fileID" style={{ display: 'none' }} />
        <button onClick={chooseFile} disabled={uploadLoading && true} className="btn-sm btn-upload"><i className="fa fa-cloud-upload fa-3x" aria-hidden="true"></i></button>
      </div>
    )
  }, [chooseFile, selectedImage, uploadLoading]);


  if (isLoading) {
    return <div className='container' style={{ marginTop: '100px', color: 'white' }}>Loading ...</div>;
  }

  if (!isAuthenticated) {
    return <SignIn />
  }

  const generatePaidVersion = () => {
    setVersion('paid');
    setImageLoading(true);
    if (!upload) {
      setTimeout(() => fetchImage(diffusionImage, 'paid'), 5000);
    } else {
      setUpload(true);
      setTimeout(() => fetchImage([paidImage], 'paid'), 5000);
    }
  }


  if(imageLoading) {
    return (
      <div className='container text-center' style={{ marginTop: '100px', color: '#F4A628' }}>
        <div className="spinner-border spinner-border-sm spinner" style={{ marginTop: '10px', color: '#F4A628' }} role="status">
          </div>
          <h4>hold tight PosterBot is making your poster 🪄</h4>
      </div>
    )
  }

  return (
    <main>
      <div className='col-md-12'>
        <div className='row'>
          <div className='col-md-5 card form-body'>
            <h3 className='text-center' style={{ marginBottom: '30px', }}>Create a Poster</h3>
            <div className='row'>
              <div className='form-group col-md-12'>
                <input
                  id="bandName"
                  type="text"
                  className='form-control input-text'
                  name="bandName"
                  onChange={handleOnChange}
                  placeholder="Band Name"
                  required
                  value={inputs.bandName}
                />
              </div>

              <div className='form-group col-md-12'>
                <input
                  id="venueName"
                  type="text"
                  className='form-control input-text'
                  name="venueName"
                  placeholder='Venue'
                  onChange={handleOnChange}
                  required
                  value={inputs.venueName}
                />
              </div>

              <div className='form-group col-md-12'>
                <input
                  id="time"
                  type="text"
                  className='form-control input-text'
                  name="time"
                  placeholder='Date & Time'
                  onChange={handleOnChange}
                  required
                  value={inputs.time}
                />
              </div>

              <div className='form-group col-md-12'>
                <input
                  id="address"
                  type="text"
                  className='form-control input-text'
                  name="address"
                  placeholder='Address'
                  onChange={handleOnChange}
                  required
                  value={inputs.address}
                />
              </div>

              <div className='form-group col-md-12'>
                <input
                  id="ticketLink"
                  type="text"
                  className='form-control input-text'
                  name="ticketLink"
                  placeholder='Ticket Link'
                  onChange={handleOnChange}
                  required
                  value={inputs.ticketLink}
                />
              </div>
            </div>

            <div className='form-group col-md-12'>
              <label >Upload an image</label>
              <label className="switch">
                <input type="checkbox" onChange={toggleUpload} />
                <span className="slider round"></span>
              </label>
            </div>

            {upload &&
              <div className="form-group">
                {uploadFile}
              </div>
            }

            {!upload &&
              <textarea
                id="imageDescription"
                name="imageDescription"
                className='form-control input-text'
                style={{ marginTop: '30px', marginBottom: '30px' }}
                rows="2"
                placeholder='Describe the art you want on your poster in as much detail as possible'
                onChange={handleOnChange}
                required
                value={inputs.imageDescription}
              />
            }

            {image === '' &&
              <button className='btn btn-md btn-generate' onClick={generateImage} style={{ marginTop: '10px' }} disabled={imageLoading}>
                {status.submitting ? (
                  <div className="spinner-border spinner-border-sm" role="status">
                  </div>
                ) : (
                  <h4 className='generate-btn-text'>Generate</h4>
                )}
              </button>
            }

            {image !== '' &&
              <div className='row'>
                <div className='col-md-6'>
                  <button className='col-md-4  btn btn-md btn-generate' onClick={generateImage} style={{ marginTop: '10px', marginBottom: '20px' }}>
                    Regenerate
                  </button>
                </div>
                <div className='col-md-6'>
                  <a download href={image} rel="noreferrer" target={'_blank'} className='col-md-4 btn btn-md btn-keep' style={{ marginTop: '10px' }}>
                    Keep
                  </a>
                </div>
              </div>
            }
          </div>

          <div className='col-md-6'>
            <>
              {image !== '' &&
                <>
                  <div className='col-md-12 row'>
                    <div className='col-md-8' id='watermarked'>
                      <div className='watermarked'>
                        <img width='100%' style={{ backgroundColor: '#fff' }} src={image} alt='generated' />
                      </div>
                      {version === 'free' &&
                        <div className='col-md-12' style={{ marginTop: '20px' }}>
                          <button type='button' data-toggle="modal" data-target="#exampleModal" className='col-md-12  btn btn-md btn-success btn-generate'>
                            Pay to remove watermark
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                </>
              }
            </>
          </div>
        </div>
      </div>
      {/* end of status.justLoaded */}
      <div className="modal" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            {/* update form */}
            <div className="modal-body">
              <div className='col-md-12'>
                {version === 'free' && image !== '' &&
                  <Elements stripe={stripePromise}>
                    <CheckoutForm />
                  </Elements>}
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" id='closeModal' className="btn btn-secondary" data-dismiss="modal">Close</button>
              {/* <button onClick={() => makeRequisition()} className="btn btn-primary">Make Request</button> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
