/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react'
import Image from "./Image";
import { useAuth0 } from "@auth0/auth0-react";
const SignIn = () => {
    const { loginWithPopup } = useAuth0();
    // login using magic link
    return (
        <>
            <div className="container">
                <div className="row" style={{marginTop: '200px'}}>
                    <div className="col-md-12 align-center text-center" style={{marginTop: '10px', marginBottom: '10px'}}>
                    <h4>(Beta)</h4>
                        {/* <h3 style={{color: 'white'}}>Login to access Poster Bot</h3> */}
                        <Image
                            src={"https://ucarecdn.com/768811e8-9001-4d1f-b82d-aae5e25cdbd8/Vector.png"}
                            alt="Login"
                            className="img-fluid"
                            width={300}
                        />
                        
                    </div>
                    {/* <div className="col-md-12 max-auto align-center">
                    <div className='form-group'>
                        <label htmlFor="email">Email</label>
                        <input
                        id="email"
                        type="email"
                        className='form-control'
                        name="email"
                        onChange={updateForm}
                        required
                        value={formState.email}
                        />
                    </div>
                    </div> */}
                    <div className="col-md-2 mx-auto text-center" style={{marginTop: '20px'}}>
                        <button onClick={() => loginWithPopup()} type="button" className="btn btn-default btn-md">
                            Login / Signup
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignIn;