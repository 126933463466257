import React from 'react';
import Header from './components/layout/Header';
import RenderForm from './components/RenderForm';
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignIn from './components/elements/Signin';

export default function App() {
  return (
    <div>
      <Router>
          <ToastContainer />
						{/* <Route path="/signup" component={SignUp} /> */}
            {/* <Header/> */}
            <Routes>
						<Route path="/login"  element={<SignIn/>} />
						<Route path="/" element={<RenderForm/>} />
            </Routes>
				</Router>
      
     
    </div>
  );
}
