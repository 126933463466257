import request from 'umi-request';
import { API_URL} from '../config/config';


export async function createDiffussionImage(body) {
  return request(`${API_URL}/stable-ai/create`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'access-control-allow-headers': '*',
      'access-control-allow-methods': '*',
      'Access-Control-Allow-Origin': '*',
      // 'Authorization' : `Token ${REPLICATE_PUBLIC_KEY}`
    },
    body: JSON.stringify(body),
  });
}

export async function getDiffussionImage(url) {
  return request(`${API_URL}/stable-ai/getImage`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'access-control-allow-headers': '*',
      'access-control-allow-methods': '*',
      'Access-Control-Allow-Origin': '*',
      // 'Authorization' : `Token ${REPLICATE_PUBLIC_KEY}`
    },
    body: JSON.stringify({url:url})
  });
}