
export const API_URL = 'https://hrmaneja-api.herokuapp.com/api/v1';
// export const API_URL = 'http://localhost:5050/api/v1'

export const BANNER_BEAR_API = 'https://api.bannerbear.com/v2';

export const REPLICATE_API = 'https://api.replicate.com/v1';

export const REPLICATE_VERSION_ID = '6359a0cab3ca6e4d3320c33d79096161208e9024d174b2311e5a21b6c7e1131c';

export const UPLOAD_CARE_PUBLIC_KEY = `b16f6e67d413898b06f8`;

export const BANNER_BEAR_PUBLIC_KEY = `bb_pr_8f9728524acbcb47a06a7e2dfcae58`;

export const BANNER_BEAR_TEMPLATE_ID = `kY4Qv7D8V4jlZB0qmP`;

export const BANNER_BEAR_FREE_TEMPLATE_ID = `y1vwRJZe1Mk2ZKNjxg`;

export const STABLE_DIFFUSION_URL = `bb_pr_102cb3266ac992f9c34d97c6abd881`;

export const STABLE_DIFFUSION_KEY = `sk-xoRbaOecpSoLrytFDkVR6rAQRcALweuPZoHdnCz6cavBUyLU`;

export const REPLICATE_PUBLIC_KEY = `40fffa5c1535f50912de6e0875a393e9196d6d53`;

export const CLOUDINARY_CLOUD_NAME = 'archer';

export const CLOUDINARY_UPLOAD_PRESET = 'poster-bot';