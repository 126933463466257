import request from 'umi-request';
import { BANNER_BEAR_API, BANNER_BEAR_PUBLIC_KEY } from '../config/config';


export async function createImage(body) {
  return request(`${BANNER_BEAR_API}/images`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Authorization' : `Bearer ${BANNER_BEAR_PUBLIC_KEY}`
    },
    body: JSON.stringify(body),
  });
}



export async function getImage(uuid) {
    return request(`${BANNER_BEAR_API}/images/${uuid}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Authorization' : `Bearer ${BANNER_BEAR_PUBLIC_KEY}`
      },
    });
  }