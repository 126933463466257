
import React from 'react'
const Loader = () => {
    return (
        <div className="container-fluid col-md-4 text-center loading-screen">
            <div className='text-center' style={{ width: '100%', height: '100%' }}>
                <div className="spinner-border spinner-border-sm spinner" style={{ width: '30px', height: '30px', marginTop: '300px' }} role="status">

                </div>
                <p className='text-center text-white'>
                Hold tight,<br/> PosterBot is generating your poster 🪄
                </p>
                <img src="https://ucarecdn.com/7099efcc-b82a-4b19-adcb-a0a688432f9e/Vector2.png" alt="loading" style={{ width: '50%', marginTop: '20px' }} />
            </div>
        </div>
    );
}

export default Loader;